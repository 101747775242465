@import "src/assets/styles/components/variables";
@import "src/assets/styles/components/mixins";

.btn {
  font-family: IranYekanRegular, serif;
  @include border-radius($radius);
  @include transition(all, 0.3s);
  padding: 0.188rem 1rem;
  font-size: 1rem;

  &:hover {
    transform: translateY(-3px);
  }

  &.wide {
    padding: 0.1rem 1.2rem;
  }

  &.btn-green-filter {
    background-color: $teal-5;
    padding: 0.25rem 1rem;
    color: $teal-1;
    border: none;

    &:hover, &.active {
      background-color: $teal-1;
      color: $white;
      border: none;
    }
  }

  &.btn-orange {
    background-color: $deep-orange-2;
    border-color: $deep-orange-2;
    color: $white !important;

    &:disabled {
      background: $deep-orange-3 !important;
      border-color: $deep-orange-3;
      opacity: 1;
      cursor: not-allowed;
      &:hover{
        transform: translateY(0px);
      }
    }
  }

  &.btn-green {
    background-color: $teal-1;
    border-color: $teal-1;
    color: $white !important;
    padding: 7px 1rem;

    &:disabled {
      background: $teal-4 !important;
      border-color: $teal-4;
      opacity: 1;
      cursor: not-allowed;
      &:hover{
        transform: translateY(0px);
      }
    }
  }

  &.btn-gray {
    background-color: $grey-2;
    border-color: $grey-2;
    color: $white !important;
    padding: 7px 1rem;
  }

  &.btn-yellow {
    background-color: $yellow-1;
    border-color: $yellow-1;
    color: $text-color !important;
    padding: 7px 1rem;
  }

  &.btn-red {
    background-color: $red-2;
    border-color: $red-2;
    color: $white !important;
    padding: 7px 1rem;
  }

}


