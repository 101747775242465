@import "~src/assets/styles/components/variables";

@mixin hover_card {
  transition: all 500ms ease;
  &:hover {
    transform: translateY(-3px);
  }
}

@mixin header-btn {
  color: $deep-orange-1;
  font-size: 1.2rem;
  width: 32px;
  height: 32px;
  border-radius: $radius;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
  padding: 0 2px;

  &:hover {
    @media #{$breakpoint-tablet} {
      background-color: transparent;
    }
  }
}

@mixin limited-text($line:9) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}

@mixin transition($element, $speed) {
  -webkit-transition: $element $speed;
  -moz-transition: $element $speed;
  -ms-transition: $element $speed;
  -o-transition: $element $speed;
  transition: $element $speed;
}

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin box-shadow-general() {
  -webkit-box-shadow: 0 3px 16px rgba(200, 205, 210, 0.5);
  -moz-box-shadow: 0 3px 16px rgba(200, 205, 210, 0.5);
  box-shadow: 0 3px 16px rgba(200, 205, 210, 0.5);
}

@mixin Linear-gradient-rtl($leftColor, $rightColor) {
  background: linear-gradient(to right, $leftColor, $rightColor);
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin purple-btn() {
  font-family: 'IranYekanLight', serif;
  box-shadow: 0 18px 40px rgba(106, 51, 178, 0.4);
  @include Linear-gradient-rtl(#380D71, #7E0D9F);
  background-color: $purple;
  @include transition(all, 0.3s);
  border-radius: $radius;
  border: none;
  color: $white;
  &:hover {
    transform: translateY(-3px);
  }
  &:disabled {
    opacity: 0.7;

    &:hover {
      transform: none;
    }
  }
}

@mixin orange-btn() {
  background: $deep-orange-1;
  @include Linear-gradient-rtl($deep-orange-1, $deep-orange-2);
  color: $white;
  font-family: IranYekanMedium, serif;
  font-size: 1rem;
  @include transition(all, 400ms);
  @include box-shadow(0, 18px, 40px, rgba(243, 140, 9, 0.4));
  @include border-radius($radius);
  height: 40px;
  border: none;
  &:hover {
    @include transform(translateY(-3px));
    color: $white;
  }
  &:disabled {
    background: $deep-orange-3;

    &:hover {
      transform: none;
    }
  }

  @media #{$breakpoint-l-desktop} {
    font-size: 0.8rem;
  }
}

@mixin orange-outline-btn() {
  background: $white;
  color: $deep-orange-1;
  border: 1px solid $deep-orange-1;
  font-family: IranYekanMedium, serif;
  font-size: 1rem;
  @include transition(all, 400ms);
  @include box-shadow(0, 18px, 40px, rgba(243, 140, 9, 0.4));
  @include border-radius($radius);
  height: 40px;
  &:hover {
    @include transform(translateY(-3px));
    color: $white;
  }

  &:disabled {
    &:hover {
      transform: none;
    }
  }

  @media #{$breakpoint-l-desktop} {
    font-size: 0.8rem;
  }
}

@mixin teal-outline-btn() {
  border: 1px solid $teal-2;
  background: $white;
  color: $teal-2;
  @include border-radius($radius);
  @include transition(all, 400ms);

  &:hover {
    @include transform(translateY(-3px));
  }

  &:disabled {
    &:hover {
      transform: none;
    }
  }

  @media #{$breakpoint-l-desktop} {
    font-size: 0.8rem;
  }
}

@mixin teal-btn() {
  border: 1px solid $teal-2;
  background: $teal-2;
  color: $white;
  @include border-radius($radius);
  @include transition(all, 400ms);

  &:hover {
    @include transform(translateY(-3px));
  }

  &:disabled {
    &:hover {
      transform: none;
    }
  }

  @media #{$breakpoint-l-desktop} {
    font-size: 0.8rem;
  }
}

@mixin rectanglesBg() {
  content: ' ';
  position: absolute;
  width: 91px;
  height: 76px;
  left: -2rem;
  top: -4.5rem;
  background: url("~src/assets/images/patterns/rectangles.svg") no-repeat;
}


