@import 'variables';
@import 'mixins';

#toast-container {
  &.toast-container {
    .ngx-toastr {
      @include border-radius($radius);
      box-shadow: none !important;
      padding: 16px 48px 16px 16px;
      background-position: 260px 16px;
      margin: 0 0 8px;
      opacity: 1;
      display: flex;
      align-items: center;

      &:before {
        font-family: "Font Awesome 5 Pro", serif;
        content: "\f058";
        font-weight: 900;
        display: inline-flex;
        position: absolute;
        font-size: 24px;
        right: 14px;
        //top: 16px;
      }

      &:hover {
        box-shadow: none !important;
      }
    }

    .toast-progress {
      background-color: $white;
      height: 6px;
      opacity: 0.3;
    }

    .toast-message {
      font-family: "IranYekanLight", serif;
      font-size: 0.875rem;
    }

    .toast-success {
      background-color: $teal-5;
      background-image: none;

      &:before {
        color: $teal-1;
      }

      .toast-message {
        color: $teal-1;
      }
    }

    .toast-warning {
      background-color: $yellow-5;
      background-image: none;

      &:before {
        color: $yellow-1;
      }

      .toast-message {
        color: $yellow-1;
      }
    }

    .toast-error {
      background-color: $red-4;
      background-image: none;

      &:before {
        color: $red-2;
      }

      .toast-message {
        color: $red-2;
      }
    }

    .toast-info {
      background-color: $blue-6;
      background-image: none;

      &:before {
        color: $blue-7;
      }

      .toast-message {
        color: $blue-7;
      }
    }
  }
}


