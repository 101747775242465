@import "../variables";
@import "../mixins";

.extra-component {
  &-layout {
    width: 100vw;
    height: 100vh;
    background-size: 100% 80%;
    position: relative;
    background-position: 0;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: ' ';
      width: 350px;
      height: 450px;
      position: absolute;
      top: 0;
      right: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("~src/assets/images/extra-layout/top-right.svg");
      @media #{$breakpoint-tablet} {
        display: none;
      }
    }

    &:after {
      content: ' ';
      width: 472px;
      height: 257px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("~src/assets/images/extra-layout/bottom-left.svg");
      @media #{$breakpoint-tablet} {
        display: none;
      }
    }
  }

  &-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 2rem;
    max-width: 740px;
    position: relative;
    z-index: 5;

    img {
      max-width: 100%;
    }

    .bottom-text {
      margin-top: 2.5rem;
    }

    .top-text {
      margin-bottom: 2.5rem;
    }

    .bottom-text, .top-text {
      font-family: IranYekanLight, serif;
      font-size: 1.75rem;
      text-align: center;

      a {
        color: $deep-orange-1;
      }

      @media #{$breakpoint-tablet} {
        font-size: 1.25rem;
      }
      @media #{$breakpoint-mobile} {
        font-size: 1rem;
      }
    }
  }
}
