@import 'variables';

.custom-modal {
  position: relative;

  &.with-background {
    border-radius: $radius;
    background: $white;
    padding: 1rem;
  }

  .close-circle {
    position: absolute;
    top: 0;
    right: -40px;
    width: 32px;
    height: 32px;
    background: $white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media #{$breakpoint-tablet} {
      top: -40px;
      right: 0;
    }

    &:before {
      content: '\f00d';
      color: $deep-orange-3;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
    }
  }

  .modal-body {
    padding: 0;
  }
}

.modal-header {
  .close {
    margin: -1rem auto -1rem -1rem;
  }
}

.modal-header,
.modal-content,
.modal-footer {
  border-color: transparent !important;
  background: transparent !important;
}

@media only screen and (max-width: 425px) {
  .modal-backdrop {
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .modal-content {
    width: 96%;
    margin-right: auto;
    margin-left: auto;

    .modal-body {
      padding: 0.5rem;
    }
  }
}
