@import './variables';
@import './mixins';

.alert {
  @include border-radius($radius);

  &.alert-primary {
    color: $blue-7 !important;
    background-color: $blue-6 !important;
    border-color: $blue-6 !important;
    padding: 1.4rem 1.5rem;
    display: flex;
    align-items: flex-start;

    > i {
      font-size: 24px;
      margin-left: 0.5rem;
    }

    span {
      color: $blue-3 !important;
      font-size: 0.875rem;

      a {
        color: $blue-2;

        &:hover {
          color: $blue-3;
        }
      }
    }

    &.small {
      padding: 0.6rem 1rem;
    }
  }

  @media #{$breakpoint-l-desktop} {
    &.alert-primary {
      padding: 1rem 1rem;
    }
    .sort-title {
      display: none;
    }
  }
}
