@import './variables';

.fo-0 {
  font-size: $fo-10 !important;
}

.fo-1 {
  font-size: $fo-11 !important;
}

.fo-2 {
  font-size: $fo-12 !important;
}

.fo-3 {
  font-size: $fo-13 !important;
}

.fo-4 {
  font-size: $fo-14 !important;
}

.fo-5 {
  font-size: $fo-15 !important;
}

.fo-6 {
  font-size: $fo-16 !important;
}

.fo-8 {
  font-size: $fo-18 !important;
}

.fo-9 {
  font-size: $fo-19 !important;
}

.fo-20 {
  font-size: $fo-20 !important;
}

.fo-22 {
  font-size: $fo-22 !important;
}

.fo-24 {
  font-size: $fo-24 !important;
}
