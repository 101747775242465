/*#region ============== font ========== */
$fo-5: 5px;
$fo-4: 4px;
$fo-10: 10px;
$fo-11: 11px;
$fo-12: 12px;
$fo-13: 13px;
$fo-14: 14px;
$fo-15: 15px;
$fo-16: 16px;
$fo-18: 18px;
$fo-19: 19px;
$fo-20: 20px;
$fo-22: 22px;
$fo-24: 24px;
$fo-25: 25px;
$fo-32: 32px;

$font-weight1: 100;
$font-weight2: 200;
$font-weight3: 300;
$font-weight4: 300;
$font-weight5: 500;
$font-weight6: 600;
$font-weight7: 700;
/*#endregion ========== font ========== */

/*#region ============ color ========== */
$black: #000000;
$white: #ffffff;

$bg-color: #FAFAFA;
$text-color: #3C3B37;
$border-color: #DCDCDC;
//$border-color: #E2E7ED;
$bg-search: rgba(226, 237, 238, 0.3);
$pink-1: #F2CECD;
$purple: #7E0D9F;
$error: #FF6F59;

$navy-blue-1: #022834;
$navy-blue-2: #032E3B;
$navy-blue-3: #1F4550;

$deep-orange-1: #F38C09;
$deep-orange-2: #F5A53F;
$deep-orange-3: #F7B25B;
$deep-orange-4: #FCE5C8;
$deep-orange-5: #FDF2E3;

$yellow-1: #FAC600;
$yellow-2: #FBD955;
$yellow-3: #FCE58D;
$yellow-4: #FDF2C6;
$yellow-5: #FEF8E2;
$yellow-6: #FFD43F;

$teal-1: #006861;
$teal-2: #026567;
$teal-3: #1E7677;
$teal-4: #3A8788;
$teal-5: #E2EDEE;

$blue-1: #1CBBE8;
$blue-2: #38C3EB;
$blue-3: #55CCEE;
$blue-4: #B3E8F7;
$blue-5: #D9F3FB;
$blue-6: #E4FCFF;
$blue-7: #14EBFF;

$red-1: #C72622;
$red-2: #E02B27;
$red-3: #F2CECD;
$red-4: #F9E8E7;

$grey-1: #ACACAC;
$grey-2: #C8CDD2;
$grey-3: #8E8E8E;
$grey-4: #F2F4F7;
$grey-5: #CACACA;
$grey-6: #E2E7ED;
$grey-7: #707070;

/*#endregion ========= color ========== */

//border-radiusP
$radius-0: 0;
$radius-1: 4px;
$radius-2: 8px;
$radius-04: .4rem;
$radius-44: 44px;
$radius: 14px;
$radius-100: 100px;

//breakpoint
$breakpoint-mobile: "only screen and (max-width : 575px)";
$breakpoint-tablet: "only screen and (max-width: 767px)";
$breakpoint-l-desktop: "only screen and (max-width: 992px)";
$breakpoint-xl-desktop: "only screen and (max-width: 1200px)";

$breakpoint-min-ultra-screen: "only screen and (min-width: 1200px)";
$breakpoint-min-xl-desktop: "only screen and (min-width: 1024px)";
$breakpoint-min-l-desktop: "only screen and (min-width: 992px)";
$breakpoint-min-l-tablet: "only screen and (min-width: 768px)";



