@import "components/reboot";
@import "components/variables";
@import "components/fonts";
@import "components/button";
@import "components/colors";
@import "components/animation";
@import "components/alert";
@import "components/form";
@import "components/accordion";
@import "components/modal";
@import "components/toaster";
@import "components/loading";
@import "components/layout/common";
@import "components/layout/login";
@import "components/layout/extra-layout";

@font-face {
  font-family: "IranYekanLight";
  src: url('../fonts/IranYekan/eot/iranyekanweblightfanum.eot');
  src: url('../fonts/IranYekan/eot/iranyekanweblightfanum.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IranYekan/woff/iranyekanweblightfanum.woff') format('woff'),
  url('../fonts/IranYekan/ttf/iranyekanweblightfanum.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IranYekanRegular";
  src: url('../fonts/IranYekan/eot/iranyekanwebregularfanum.eot');
  src: url('../fonts/IranYekan/eot/iranyekanwebregularfanum.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IranYekan/woff/iranyekanwebregularfanum.woff') format('woff'),
  url('../fonts/IranYekan/ttf/iranyekanwebregularfanum.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IranYekanMedium";
  src: url('../fonts/IranYekan/eot/iranyekanwebmediumfanum.eot');
  src: url('../fonts/IranYekan/eot/iranyekanwebmediumfanum.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IranYekan/woff/iranyekanwebmediumfanum.woff') format('woff'),
  url('../fonts/IranYekan/ttf/iranyekanwebmediumfanum.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IranYekanBold";
  src: url('../fonts/IranYekan/eot/iranyekanwebboldfanum.eot');
  src: url('../fonts/IranYekan/eot/iranyekanwebboldfanum.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IranYekan/woff/iranyekanwebboldfanum.woff') format('woff'),
  url('../fonts/IranYekan/ttf/iranyekanwebboldfanum.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



