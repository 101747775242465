@import '../variables';
@import '../mixins';

/*#region default*/
html, body {
  position: relative;
  min-height: 100%;
  font-family: 'IranYekanRegular', serif;
  font-size: 100%;
  direction: rtl;
  text-align: right;
  background-color: $bg-color;
}

a {
  @include transition(all, 0.3s);
}

.light {
  font-family: 'IranYekanLight', serif;
}

.d-rtl {
  direction: rtl;
}

.d-ltr {
  direction: ltr;
}

.green {
  color: $teal-1;
}

.red {
  color: $red-2;
}

pre {
  direction: ltr;
  text-align: left;
  word-break: break-all;
}

.pointer {
  cursor: pointer;
}

.header-container {
  font-family: "IranYekanBold", serif;
  font-size: 1.5rem;
  margin-bottom: 2rem;

  @media #{$breakpoint-mobile} {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  &.no-border {
    border-bottom: 0;
  }

  &.small {
    font-size: $fo-20;
    margin-top: 18px;
    margin-bottom: 1rem;
  }
}

.header-box {
  margin-bottom: 1.0rem;
  position: relative;

  h1 {
    font-family: 'IranYekanBold', serif;
    font-size: 1.25rem;
    margin-bottom: 1.0rem;
    line-height: 2.5rem;
  }

  p {
    font-family: 'IranYekanRegular', serif;
    font-size: 1rem;
  }
}

.tabs {
  &_list {
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;

    li {
      color: $teal-1;
      border: 0;
      padding: 0 5px 16px;
      cursor: pointer;

      &.active {
        border-bottom: 2px solid $navy-blue-2;
        color: $navy-blue-2;
        font-family: 'IranYekanBold', serif;
      }
    }
  }

  &_body {
    padding: 30px 0 0;
  }
}

.error-place {
  p {
    font-family: 'IranYekanRegular', serif !important;
    margin: 0.5rem 0 !important;
    text-align: right !important;
    font-size: 12px !important;
    color: $error !important;
    direction: rtl;

    i {
      vertical-align: middle !important;
    }
  }

}

.main-container {
  margin-bottom: 8.5rem;
  @media #{$breakpoint-tablet} {
    min-height: auto !important;
    margin-bottom: 4.5rem;
    padding: 0;
  }
}

.h_iframe-aparat_embed_frame {
  position: relative;
  overflow: hidden;
  margin: 0 auto;

  .inner-iframe {
    display: block;
    padding-top: 57%
  }

  .ratio {
    display: block;
    width: 100%;
    height: auto;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 4px;
  }
}

.custom_card {
  background-color: $white;
  border: 1px solid $border-color;
  padding: 1rem 1.5rem;
  border-radius: $radius;

  &_title {
    border-bottom: 1px solid $border-color;
    padding: 0 0 5px;
    margin-bottom: 5px;

    h6 {
      color: $teal-1;
    }

    span {
      font-size: $fo-14;
      color: $text-color;
    }
  }
}

/*----Sliders Styles----*/
.carousel {
  //direction: ltr;
  direction: rtl;
  @media screen and (max-width: 540px) {
    direction: ltr !important;
  }
}

.webinar_carousel {
  color: #fff;
  margin-bottom: 0 !important;

  &.slick-slider .slick-track,
  &.slick-slider .slick-list {
    transform: none;
  }

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }
}

.slick-loading {
  .slick-track {
    visibility: hidden;
  }
}

.slick-list.draggable {
  direction: rtl;
  padding-top: 34px;
  @media screen and (max-width: 540px) {
    direction: ltr !important;
  }
  @media #{$breakpoint-mobile}{
    padding-bottom: 16px !important;
    padding-top: 34px !important;
  }
}

.banner-slick {
  .product_item {
    @media screen and (min-width: 576px) {
      box-shadow: none !important;
    }
  }

  .slick-arrow {
    @include transition(all, 0.3s);

    &:before {
      opacity: 1;
      font-size: 1.5rem;
    }

    &.slick-disabled {
      opacity: 0.5;
    }

    &.slick-prev {
      right: -25px;
      left: 0;

      &:before {
        content: "→";
      }

      &:hover {
        @include transform(translate(3px, -50%));
      }
    }

    &.slick-next {
      right: auto;
      left: -25px;

      &:before {
        content: "←";
      }

      &:hover {
        @include transform(translate(-3px, -50%));
      }
    }

    &.slick-disabled {
      &:hover {
        @include transform(translate(0, -50%));
      }
    }

    @media #{$breakpoint-tablet} {
      display: none !important;
    }
  }
}

.container-slide {
  width: 100%;
  padding: 4px 8px 0;
}

.categories-slider {
  &.participants-video-carousel {
    .slick-list.draggable {
      padding-top: 16px;
    }

    .slick-arrow {
      top: -28px;
    }
  }

  .product_item {
    box-shadow: 0 3px 14px rgb(200 205 210 / 50%) !important;
  }

  .slick-list.draggable {
    padding-bottom: 16px;
  }

  .slick-arrow {
    @include transition(all, 0.3s);
    @include square(24px);
    border-radius: $radius-100;
    background-color: $teal-5;
    position: absolute;
    top: -48px;
    padding: 0;
    z-index: 2;
    border: none;
    font-size: 0;
    display: block;
    color: $teal-2;

    i {
      font-size: 14px;
      line-height: 24px;
    }

    &.slick-disabled {
      opacity: 0.5;
    }

    &.slide-prev {
      left: 32px;

      i {
        &:before {
          padding-left: 2px;
        }
      }

      &:hover {
        @include transform(translateX(3px));
      }
    }

    &.slide-next {
      right: calc(100% - 24px);

      &:hover {
        @include transform(translateX(-3px));
      }

      i {
        &:before {
          padding-right: 2px;
        }
      }
    }

    &.slick-disabled {
      &:hover {
        transform: none;
      }
    }

    @media #{$breakpoint-tablet} {
      &.slide-prev {
        left: 54px;
      }

      &.slide-next {
        right: calc(100% - 45px);
      }
    }
  }
}

/*----Sliders Styles----*/

.webinar-card {
  padding: 0;
  margin-bottom: 0.5rem;
}

.price_cell {
  .old_price {
    position: relative;

    &:before {
      position: absolute;
      content: ' ';
      width: 100%;
      height: 1px;
      background: $deep-orange-1;
      top: 50%;
      left: 0;
    }
  }

  .price {
    color: $deep_orange-1 !important;
    font-size: 16px !important;
    font-weight: bold;
  }

}

.alert-wrap {
  font-family: 'IranYekanLight', serif;
  @include border-radius($radius);
  vertical-align: middle;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;

  i {
    vertical-align: middle;
    margin-left: 0.5rem;
    font-size: 1.125rem;
  }

  &.info {
    background-color: $blue-6;
    color: $blue-7;

    span {
      background-color: inherit;
      color: inherit;
    }

    a {
      color: $blue-7;
    }

    i {
      color: $blue-7;
    }
  }

  &.warning {
    background-color: $yellow-5;
    color: $yellow-1;

    span {
      background-color: inherit;
      color: inherit;
    }

    a {
      color: $yellow-1;
    }

    i {
      color: $yellow-1;
    }
  }

  &.warning {
    background-color: $yellow-5;
    color: $yellow-1;

    span {
      background-color: inherit;
      color: inherit;
    }

    a {
      color: $yellow-1;
    }

    i {
      color: $yellow-1;
    }
  }
}

div.pagination {
  margin-top: 1.5rem;
  justify-content: flex-end;
}

.setting-list {
  /* display: flex;
   flex-direction: column;
   align-items: flex-start;*/

  &-item {
    //border-bottom: 1px solid $border-color;
    padding: 1.5rem 0;
    width: 100%;

    span {
      font-family: "IranYekanRegular", serif;
    }

    &:last-child {
      border: 0;
    }
  }
}

.scrollbar-style {
  //height: 52vh;
  height: 388px;
  overflow-y: auto;
  direction: ltr;
  scrollbar-color: #D1D5DB #F5F5F5;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;

  .scrollbar-style-inner {
    direction: rtl;
  }
}

.scrollbar-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.45);
  -moz-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.45);
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.45);
  background-color: #F5F5F5;
}

.scrollbar-style::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.45);
  -moz-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.45);
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.45);
  @include border-radius(4px);
  background-color: #D1D5DB;
  min-height: 30px;

  @media #{$breakpoint-mobile} {
    background-color: #afb1b3;
    //box-shadow: inset 0 0 6px rgb(140 139 139 / 0.45);
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch;
  }
}

/*
.custom-pagination {
  ul.pagination {
    li.page-item {
      width: 40px;
      height: 40px;
      margin: 0.5rem;

      a {
        width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $radius-1;
        font-size: 1rem;
        border: 1px solid $border-color;
        color: $teal-1;

        &:focus {
          box-shadow: none;
        }

        i {
          color: $teal-5;
        }
      }

      &.active {
        a {
          background: $teal-1;
          color: $white;
        }
      }
    }
  }
}
*/

.scrollbar-style {
  padding-top: 0.5rem;

  .col-12 {
    &:last-child {
      .box-shadow {
        margin-bottom: 0.25rem;
      }
    }
  }
}

.dropdown-menu {
  @include box-shadow-general();
  border-radius: $radius;
  background: $white;
  border-width: 0;
}

@media screen and (min-width: 767px) and (max-width: 800px) {
  .row-reverse-sm {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .row-reverse-sm {
    flex-wrap: wrap-reverse;
  }
}

@media #{$breakpoint-mobile} {
  .row-reverse-sm {
    flex-wrap: wrap-reverse;
  }
}


.logo-watermark {
  text-align: center;
  margin-top: -8rem;
  width: 100%;

  @media #{$breakpoint-tablet} {
    margin-top: -6rem;
  }

  img {
    max-width: 100%;
  }
}

.btn-login {
  @include orange-btn();
  width: 150px;
  @media #{$breakpoint-l-desktop} {
    width: 106px;
  }
}

.webinar-details-title {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  span {
    font-family: IranYekanBold, serif;
    font-size: 1.25rem;
    margin-right: 0.5rem;
  }
}

.modal-backdrop {
  background: $navy-blue-2;

  &.show {
    opacity: 0.7;
  }
}

.modal-default {
  @media #{$breakpoint-min-l-tablet} {
    max-width: 650px;
  }
}

.pagination {
  .page-item {
    margin-left: 0.5rem;
    height: 40px;
    width: 40px;

    .page-link {
      @include box-shadow-general();
      border-radius: $radius;
      text-align: center;
      color: $teal-2;
      border: none;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.active {
      .page-link {
        background-color: $teal-5;
      }
    }
  }
}

.gray-color {
  color: $grey-5;
}

.live-mode {
  position: relative;
  display: inline-block;
  margin-left: 0.25rem;

  span {
    background: $red-2;
  }

  .flushing {
    box-shadow: 0 0 0 0 rgba(224, 43, 39, 0.8);
    animation: pulse 2s infinite;
    transform: scale(1);
    border-radius: $radius-100;
    display: inline-block;
    height: 10px;
    width: 10px;
  }
}

.cart-item {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  margin-bottom: 2rem;
  padding: 1rem 8px;

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media #{$breakpoint-tablet} {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media #{$breakpoint-mobile} {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
