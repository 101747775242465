@import "../variables";
@import "../mixins";

.login-banner {
  background: url(../../../images/login/login-pattern.svg) no-repeat right center;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.login-container {
  min-height: 100vh;

  h1 {
    font-family: 'IranYekanLight', serif;
    font-size: 40px;
    color: $white;
  }

  .col {
    padding: 0;

    &:last-child {
      flex: 0 0 612px;
      max-width: 612px;
    }

    &:first-child {
      flex: 0 0 calc(100% - 612px);
      max-width: calc(100% - 612px);
    }

    @media screen and (min-width: 1024px) and (max-width: 1500px) {
      &:last-child {
        flex: 0 0 42%;
        max-width: 42%;
      }

      &:first-child {
        flex: 0 0 58%;
        max-width: 58%;
      }
    }
    @media screen and (min-width: 920px) and (max-width: 1023px) {
      &:last-child {
        flex: 0 0 48%;
        max-width: 48%;
      }

      &:first-child {
        flex: 0 0 52%;
        max-width: 52%;
      }
    }
    @media screen and (max-width: 919px) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      &:first-child {
        margin-top: 3rem;
      }
    }
  }

  .flow-item {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .login-box {
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .auth-form {
    text-align: center;
    width: 390px;

    .form {
      padding: 0 2rem;
    }

    img {
      margin-bottom: 1.5rem;
    }

    button {
      line-height: 1.2;
      height: 32px !important;
      @include orange-btn();
    }

    .form-field {
      margin-bottom: 1.5rem;
    }
  }

  ::placeholder {
    font-size: 12px;
    line-height: 2rem;
    direction: ltr;
    text-align: right;
  }

  .form-login {
    p {
      font-family: 'IranYekanLight', serif;
      margin-bottom: 1rem;
      padding: 0;
      //margin-top: 1.5rem;
    }

    .privacy-rule {
      border-top: 1px solid $border-color;
      margin-top: 2.5rem;

      p {
        margin-top: 1rem;
        text-align: right;
        font-size: 15px;
      }

      a {
        color: $teal-1;

        &:hover, &:active {
          color: $teal-2;
        }
      }
    }
  }

  .form-verification {
    .form {
      direction: ltr;

      p {
        &:first-child {
          font-family: "IranYekanMedium", serif;
          margin-bottom: 1rem;
          padding: 0;
        }

        &:nth-child(2) {
          font-family: "IranYekanLight", serif;
          margin-bottom: 1.5rem;
          padding: 0;
        }
      }
    }

    .otp-group {
      display: flex;
      margin-bottom: 2rem;
      justify-content: space-between;
    }

    .tel-owner {
      border-top: 1px solid $border-color;
      margin-top: 1.0rem;
      padding-top: 1rem;

      p, span {
        font-family: 'IranYekanLight', serif;
        color: $deep-orange-1;
        cursor: pointer;

        &:hover {
          color: $deep-orange-2;
        }
      }
    }

    .countDownTimer {
      p {
        font-family: "IranYekanMedium", serif;
        font-size: 14px;
        padding: 0;

        &.resend {
          font-size: 16px;
        }
      }

      .resend {
        color: $deep-orange-1;
        cursor: pointer;

        &:hover {
          color: $deep-orange-2;
        }
      }
    }
  }

  /*  @media #{$breakpoint-min-xl-desktop} {
      !*.col-md-4 {
        flex: 0 0 427px;
        max-width: 427px;
      }
      .col-md-8 {
        flex: 0 0 calc(100% - 427px);
        max-width: calc(100% - 427px);
      }*!
    }
    @media screen and (min-width: 905px) and (max-width: 1023px) {
      .col-md-4 {
        flex: 0 0 340px;
        max-width: 340px;
      }
      .col-md-8 {
        flex: 0 0 calc(100% - 340px);
        max-width: calc(100% - 340px);
      }
      .auth-form {
        max-width: calc(100% - 8rem);
        padding: 2.0rem 3.5rem;
        margin: auto;
      }
      .login-box-title {
        max-width: calc(100% - 8rem);
        margin: auto auto 16px;
      }
    }

    @media screen and (min-width: 801px) and (max-width: 904px) {
      .col-md-4 {
        flex: 0 0 340px;
        max-width: 340px;
      }
      .col-md-8 {
        flex: 0 0 calc(100% - 340px);
        max-width: calc(100% - 340px);
      }
      .auth-form {
        max-width: calc(100% - 6rem);
        padding: 2.0rem 3.0rem;
        margin: auto;
      }
      .login-box-title {
        max-width: calc(100% - 6rem);
        margin: auto auto 16px;
      }
    }

    @media screen and (min-width: 767px) and (max-width: 800px) {
      .col-md-4 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-md-8 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    @media screen and (max-width: 800px) {
      .row {
        flex-wrap: wrap-reverse;
      }
      .login-banner {
        height: 55vh;
      }
      .login-box-title {
        margin: 30px auto 16px;
      }
    }

    @media #{$breakpoint-mobile} {
      h1 {
        text-align: center;
        font-size: 28px;
        width: 215px;
      }
      .row {
        flex-wrap: wrap-reverse;
      }
      .login-banner {
        height: 30vh;
      }
      .login-box-title {
        max-width: 100%;
        padding: 0 1rem;
        margin: 2.0rem auto 0;
        text-align: center;

        h2 {
          font-size: 24px;
          margin-bottom: 0;
        }
      }
      .auth-form {
        background-color: $bg-color;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        max-width: 100%;
        width: 100%;
        padding: 1.25rem;
        margin: auto;
      }

      .form-login {
        .privacy-rule {
          margin-top: 1.5rem;

          p {
            font-size: 14px;
          }
        }
      }

      .form-verification {
        .form {
          > p {
            margin-top: 1.5rem;
          }
        }

        .otp-group {
          margin-bottom: 1.25rem;
        }

        .tel-owner {
          margin-top: 1rem;
        }
      }
    }*/
}

@media screen and (max-width: 919px) {
  .login-banner {
    background: url(../../../images/login/m-login-pattern.svg) no-repeat bottom center;
    background-size: cover;
    width: 100%;
  }
  .login-container {
    .row {
      flex-wrap: wrap-reverse;
    }
  }

}

@media screen and (min-width: 576px) and (max-width: 919px) {
  .login-banner {
    height: 38vh;
  }
  .login-container {
    .auth-form {
      max-width: 354px;
    }
  }
}

@media screen and (max-width: 575px) {
  .login-banner {
    height: 38vh;
  }
  .login-container {
    .auth-form {
      width: 318px;
    }
  }

}

