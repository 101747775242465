@import "variables";
@import "mixins";

.accordion {
  &.white_body_accordion {
    .card {
      background: $white;
      @include box-shadow-general();
      @include border-radius($radius);
      margin: 0;
      padding: 0;

      .card-header {
        background-color: $white;
        border: 0;

        button {
          background: $white;
          padding: 1rem;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            display: flex;
            align-items: center;
            width: calc(100% - 1rem);

            &-img {
              width: 2.5rem;
              height: 2.5rem;
              margin-left: 0.5rem;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            span {
              width: calc(100% - 3rem);
            }
          }

          > span {
            width: 1rem;
            height: 1rem;
          }
        }
      }

      .card-body {
        padding: 0 1rem;
      }
    }
  }

  .card {
    font-family: "IranYekanRegular", serif;
    border-radius: $radius !important;
    @include box-shadow-general();
    background: $white;
    font-size: 16px;
    border: none;
    color: $text-color;
    margin-bottom: 1rem;
    padding: 20px 24px;

    .card-header {
      border-radius: $radius;
      margin-bottom: 0;
      padding: 0;
    }
  }

  .card-body {
    color: $navy-blue-2;
    padding: 1rem 1rem 0 0;

    p, span {
      color: inherit;
    }
  }

  .card-header {
    background-color: $white;
    border: none;

    button {
      color: $navy-blue-2;
      text-align: right;
      display: flex;
      width: 100%;
      padding: 0;

      span {
        font-family: inherit;
        font-size: inherit;
        color: $navy-blue-2;
        display: flex;
        flex-grow: 1;

        i {
          padding-top: 4px;
        }
      }
      &:hover {
        transform: none;
      }
    }

    .btn-link {
      &:hover, &:focus {
        text-decoration: none;
        color: $navy-blue-2;
      }
    }

  }

  @media #{$breakpoint-mobile} {
    .card-body {
      font-size: 15px;
    }
    .card-header {
      button {
        font-size: 15px;
      }
    }
  }

}
