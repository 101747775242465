@import "./variables";
@import "./mixins";

input {
  &.form-control {
    border: 1px solid $border-color;
    font-family: 'IranYekanRegular', serif;
    @include border-radius($radius);
    @include transition(all, 800ms);
    padding: 7px 1rem;
    font-size: 0.875rem;
    color: $grey-3;
    height: 32px;

    &:focus {
      color: #495057;
      border-color: $deep-orange-1;
      outline: 0;
      box-shadow: none;
    }

    &:read-only {
      cursor: default;
    }

    &.border-error {
      border: 1px solid $error;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 12px !important;
      color: $border-color !important;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      font-size: 12px !important;
      color: $border-color !important;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      font-size: 12px !important;
      color: $border-color !important;
    }

    &:-moz-placeholder { /* Firefox 18- */
      font-size: 12px !important;
      color: $border-color !important;
    }

    &::placeholder {
      font-size: 12px !important;
      color: $border-color !important;
    }

  }

  &.otp-control {
    min-width: 0;
    width: calc(100% / 5 - 1.5rem);
    background: transparent;
    display: inline-block;
    height: 32px;
    text-align: center;
    border-color: $border-color;
    border-width: 0 0 1px 0;
    padding: 6px 0 2px 0;
    border-radius: 0;

    &:last-child {
      //margin-right: 0;
    }

    &:focus {
      border-color: $blue-1;

      & ~ input {
        border-color: $blue-1;
      }
    }

    @media #{$breakpoint-mobile} {
      width: calc(100% / 5 - 1.2rem);
    }
  }

  &.otp-input {
    width: 100%;
    letter-spacing: 28px;
    background: transparent;
    height: 32px;
    text-align: center;
    border: 1px solid $border-color;
    padding: 6px 0 2px 0;
    border-radius: $radius;

    &:last-child {
      //margin-right: 0;
    }

    &:focus {
      border-color: $blue-1;

      & ~ input {
        border-color: $blue-1;
      }
    }
  }

}

select {
  &.form-control {
    height: 32px;
    border: 1px solid $border-color;
    padding: 0.3125rem 1rem 0.375rem 1rem;
    font: -moz-pull-down-menu;
    font-family: 'IranYekanRegular', serif;
    @include border-radius($radius-100);
    background-color: $white;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-angle-down fa-w-10 fa-3x'%3E%3Cpath fill='currentColor' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z' class=''%3E%3C/path%3E%3C/svg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    background-repeat: no-repeat, repeat;
    background-position: left 1em top 50%, 0 0;
    background-size: .65em, 100% auto;
    font-size: 14px;
    //font-size: 0.75rem;
    color: $grey-3;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      color: #495057;
      border-color: $deep-orange-1;
      outline: 0;
      box-shadow: none;
    }

    &.border-error {
      border: 1px solid $error;
    }

    option {
      padding: 0.3125rem 0.5rem;
      font-size: 14px;
      font-family: "IranYekanRegular", serif;
      font: -moz-pull-down-menu;
      //Doesnt work
      &:hover {
        background: linear-gradient(#000000, #000000);
        background-color: $border-color !important; /* for IE */
        outline: red none;
      }

      &:active, &:focus {
        background: linear-gradient(#000000, #000000);
        background-color: #000000 !important; /* for IE */
        color: red !important;
        outline-color: red
      }

    }

  }
}

.custom-control {
  &.custom-checkbox,
  &.custom-radio {
    padding-right: 38px;
    padding-left: 15px;

    .custom-control-label {
      display: inline-block !important;
      cursor: pointer;

      &:before {
        //background-color: transparent;
        right: -1.5rem;
        left: auto;
      }

      &::after {
        right: -1.5rem;
        left: auto;
      }
    }

    .custom-control-input {
      right: 0;

      &:focus {
        & ~ label {
          &::before {
            box-shadow: none;
            border-color: #adb5bd;
          }
        }
      }

      &:active {
        & ~ label {
          &::before {
            background-color: transparent;
            border-color: $deep-orange-1;
            box-shadow: none;
          }
        }
      }

      &:checked {
        & ~ label {
          &::before {
            border-color: $deep-orange-1;
            background: $deep-orange-1;
          }
        }
      }
    }
  }

  &.custom-switch {
    padding-right: 28px;
    padding-left: 0;

    .custom-control-label {
      padding-right: 0.75rem;
      cursor: pointer;

      &:before {
        background-color: $grey-1;
        border-color: $grey-1;
        right: -1.5rem;
        left: auto;
      }

      &::after {
        background-color: $white;
        right: calc(-1.5rem + 2px);
        left: auto;
      }
    }

    .custom-control-input {
      right: 0;

      &:focus {
        & ~ label {
          &::before {
            box-shadow: none;
          }
        }
      }

      &:checked {
        & ~ label {
          &::before {
            border-color: $deep-orange-1;
            background: $deep-orange-1;
          }
        }

        & ~ .custom-control-label {
          &::after {
            transform: translateX(-0.75rem);
          }
        }
      }

      &:not(:disabled):active{
        ~ .custom-control-label{
          &::before{
            background-color: $deep-orange-4;
            border-color: $deep-orange-3;
          }
        }
      }

      &:focus:not(:checked){
        ~ .custom-control-label {
          &::before{
            border-color: $deep-orange-3;
          }
        }
      }
    }
  }

}

.form-wrapper {
  margin: 1.5rem 0 0.5rem 0;

  .numeric {
    direction: ltr;
    text-align: left;

    &::placeholder {
      direction: ltr;
      text-align: left;
    }
  }

  label {
    padding: 0;
    display: block;
    text-align: right;
    margin-bottom: 1.5rem;
    @media #{$breakpoint-mobile} {
      margin-bottom: 1rem;
    }

    span {
      font-family: "IranYekanRegular", serif;
      margin-bottom: 0.5rem;
      line-height: 1.1rem;
      display: inline-block;
      vertical-align: middle;
      color: $grey-3;
    }
  }

  span {
    &.required {
      margin-right: 0.25rem;
      color: $error;
    }
  }

  .checkbox-inline {
    input {
      margin-left: 8px;
    }
  }

  @media #{$breakpoint-tablet} {
    label {
      margin-bottom: 1.5rem;
    }
  }
}

textarea {
  resize: none;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 12px !important;
    color: $border-color !important;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    font-size: 12px !important;
    color: $border-color !important;
  }

  &::placeholder {
    font-size: 12px !important;
    color: $border-color !important;
  }

  &.form-control {
    &:focus {
      box-shadow: none;
    }
  }
}

.textarea-control {
  //text-indent: 10px;
  line-height: 1.5rem;
  width: 100%;
  height: 100%;
  border: 1px solid $border-color;
  background: $white;
  padding: 0.5rem;
  @include transition(all, 800ms);

  @include border-radius($radius);

  &:focus {
    border-color: $deep-orange-1;
    outline: 0;
    box-shadow: none;
  }

  &.border-error {
    border: 1px solid $error;
  }
}
